
					@import './src/sass/variables';
					@import './src/sass/mixins';
				













































.freetext.block {
	textarea {
		max-width: 600px;
	}
}
